<template>
    <div style="margin-bottom: 10%">
        <h2 class="ContactUs">
          Contact Us
          <v-divider/>
        </h2>
        <v-row style="text-align: left">
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <v-row>
                    <v-col v-for="(item, i) in items" :key="i" cols="12" md="4">
                        <v-card flat class="card">
                            <v-container>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    large
                                    color="cyan"
                                >
                                    <a class="link" :href="item.href" target="_blank">
                                    <v-icon dark>
                                        {{ item.icon }}
                                    </v-icon>
                                    </a>
                                </v-btn>
                                <v-card-text>
                                    <h2>{{ item.title }}</h2>
                                </v-card-text>
                                <p class="cardBody" v-for="(line, i) in item.additional" :key="i"> {{ item.additional[i] }} </p>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data: () => ({
        components: {
            Carousel: () => import('../components/Carousel.vue'),
            Gallery: () => import('../components/Gallery.vue'),
            About: () => import('../components/About.vue'),
            Amenities: () => import('../components/Amenities.vue')
        },
        items: [
            {   
                title: 'Send us an email', 
                icon: 'mdi-email',
                href: 'mailto:riverviewbarn.sd@gmail.com?subject=Riverview%20Barn',
                additional: [
                    'riverviewbarn.sd@gmail.com'
                ]
            },
            {
                title: 'Call or text us',
                icon: 'mdi-phone',
                href: 'tel:+1-605-400-1510',
                additional: [
                    '605-400-1510'
                ]
            },
            { 
                title: 'Visit our location', 
                icon: 'mdi-map-marker',
                href: 'https://goo.gl/maps/ZzkSCWxiJCgwpXBn8',
                additional: [
                    '2400 S. Riverview Avenue',
                    'Sioux Falls, South Dakota 57110'
                ]
            },
        ]
    })
}
</script>

<style scoped>
.ContactUs {
  padding: 5%;
}
.card{
    text-align: center;
}
.cardBody {
    line-height: 14px;
}
.link {
    text-decoration: none;
    color: unset;
}
</style>